import React, { FunctionComponent } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { QuestionIcon } from '../MenuIcons/Icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface IQuestion {
    title: string;
    placement?: TooltipProps['placement'];
}

const useStyles = makeStyles(() =>
    createStyles({
        tooltip: {
            backgroundColor: '#ffffff',
            boxShadow: '0px 4px 32px rgba(0, 16, 61, 0.32)',
            borderRadius: '4px',
            color: '#2C2D2E',
            fontSize: '13px',
            lineHeight: '18px',
            whiteSpace: 'pre-line',
            marginBottom: '8px',
        },
    })
);

const Question:FunctionComponent<IQuestion> = ({ title= '', placement = 'top'}) => {
    const classes = useStyles();

    return <Tooltip
        interactive={true}
        title={title}
        classes={classes}
        placement={placement}>
            <span style={{ margin: '8px' }}>{QuestionIcon()}</span>
    </Tooltip>;
}

export default Question;
