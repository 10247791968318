import React, {forwardRef} from 'react';
import {
    Admin,
    AppBar,
    getResources,
    Layout,
    MenuItemLink,
    Resource,
    usePermissions,
    UserMenu,
    useTranslate
} from 'react-admin';
import authProvider from './DataProvider/authProvider';
import Dashboard from './Dashboard';
import LoginPage from './components/auth/LoginPage';
import LogoutButton from './components/auth/LogoutButton';
import GraphList from './components/Analytics/GraphList.tsx';
import MChatDumpForm from "./components/MChatDump/MChatDumpForm";
import Settings from "./components/Settings/Settings";
import GroupList from './components/GroupSearch/GroupList.tsx';
import userDataProvider from "./DataProvider/godmod";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {Messages} from './i18n'
import Typography from '@material-ui/core/Typography';
import {createMuiTheme, makeStyles, withStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {useMediaQuery} from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import {AnalyticsIcon, DashboardIcon, ExportIcon, MyteamLogo} from "./components/MenuIcons/Icons";

const i18nProvider = polyglotI18nProvider(locale =>
        Messages[locale], 'ru'
);

const theme = createMuiTheme({
    sidebar: {
        closedWidth: 50
    },
});

const useStyles = makeStyles({
    root: {
        backgroundColor: '#161251'
    },
    logo: {
        width: '111px',
        height: '28px',
        verticalAlign: 'middle'
    },
    title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    spacer: {
        flex: 1,
        '& + button': {
            display: 'none'
        }
    },
});

const StyledMenuItemLink = withStyles({
    icon: {
        minWidth: '20px',
        width: '20px',
        height: '20px',
        marginRight: '14px',
        '& > *': {
            width: '100%',
            height: '100%'
        }
    }
})(MenuItemLink);

const CheckResourcePermission = (resource_name) => {
    const user_groups = JSON.parse(localStorage.getItem("user_groups"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    if (!permissions) {
        return false;
    }

    if (!permissions["Resources"][resource_name]) {
        return false;
    }

    if (permissions["Resources"][resource_name]["Default"] === "allow") {
        return true;
    }

    if (!permissions["Resources"][resource_name]["Groups"]) {
        return false;
    }

    const resource_groups_set = new Set(permissions["Resources"][resource_name]["Groups"]);
    const user_groups_set = new Set(user_groups);
    const intersection = new Set([...user_groups_set].filter(g => resource_groups_set.has(g)));

    return intersection.size !== 0;
};

const MyteamAdminMenu = (props)   => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const translate = useTranslate();

    usePermissions();

    return (
        <div>
            {resources.map(resource => {
                if (!CheckResourcePermission(resource.name))
                    return null

                return (
                    <StyledMenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translate("resources." + resource.name + ".name")}
                        leftIcon={
                            resource.icon ? <resource.icon/> : <DefaultIcon/>
                        }
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                );
            })}

            {isXSmall && props.logout}
        </div>
    );
};

const StyledUserProfileMenu = withStyles({
    icon: {
        minWidth: '20px',
        width: '20px',
        height: '20px',
        marginRight: '10px',
    },
    root: {
        color: '#000',
        alignItems: 'end',
    }
})(MenuItemLink);

const UserProfileMenu = forwardRef(({ onClick }, ref) => {
    const email = localStorage.getItem('email');
    return (
        <StyledUserProfileMenu
            ref={ref}
            to="#"
            primaryText={email}
            button={false}
            component='li'
            sidebarIsOpen={true}
            leftIcon={<PersonIcon />}
        />
    );
});

const MyteamAdminUserMenu = props => (
    <UserMenu {...props}>
        <UserProfileMenu />
    </UserMenu>
);

const MyteamAdminAppBar = withStyles({
    menuButton: {
        margin: '0 2px'
    }
})(props => {
    const classes = useStyles();
    return (
        <AppBar className={classes.root} {...props} userMenu={<MyteamAdminUserMenu />} >
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
                children={<MyteamLogo className={classes.logo}/>}
            />
            <span className={classes.spacer} />
        </AppBar>
    );
});

const MyteamAdminLayout = (props) => {
    return <Layout {...props} appBar={MyteamAdminAppBar} menu={MyteamAdminMenu}/>;
};

const App = () => (
    <Admin
        theme={theme}
        layout={MyteamAdminLayout}
        loginPage={LoginPage}
        logoutButton={LogoutButton}
        authProvider={authProvider}
        dataProvider={userDataProvider}
        // locale="en"
        i18nProvider={i18nProvider}
    >

        {/*<Resource name="Users" list={UserList} edit={UserEdit} />*/}
        {permissions => {
            return [
                CheckResourcePermission("Information") &&
                <Resource name="Information" list={Dashboard} icon={DashboardIcon} />,

                CheckResourcePermission("Analytics") &&
                <Resource name="Analytics" list={GraphList} icon={AnalyticsIcon}/>,

                CheckResourcePermission("Export") &&
                <Resource name="Export" list={MChatDumpForm} icon={ExportIcon}/>,

                CheckResourcePermission("Settings") &&
                <Resource name="Settings" list={Settings} icon={SettingsIcon}/>,

                CheckResourcePermission("Management") &&
                <Resource name="Management" list={GroupList}/>,
            ];
        }}
    </Admin>
);

export default App;
