const urlPrefix = process.env.REACT_APP_API_HOST + '/auth';
const apiPrefix = process.env.REACT_APP_API_HOST + '/api';

function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}

function logout() {
    const request = new Request(`${urlPrefix}/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    fetch(request)
        .then(response => {
            if (response.status !== 200) {
                throw new Error("Internal server error");
            }
        });
}

const authProvider = {
    OTP: ({ email, password }) =>  {
        if (password === "" || password === undefined) {
            const otpGenerateURL = `${urlPrefix}/otp/generate`;
            const request = new Request(otpGenerateURL, {
                method: 'POST',
                body: JSON.stringify({ email }),
                credentials: 'include',
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });

            return fetch(request)
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error("Internal server error");
                    }

                    return response.json();
                })
                .then((response) => {
                    if (response.status.code !== 200) {
                        throw new Error(response.status.reason);
                    }
                    return response
                })
        }

        const otpCheckURL = `${urlPrefix}/otp/check`;
        const request = new Request(otpCheckURL, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            credentials: 'include',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("Internal server error");
                }

                return response.json();
            })
            .then((response) => {
                if (response.status.code !== 202) {
                    throw new Error(response.status.reason);
                }

                localStorage.setItem('authenticated', true);
                localStorage.setItem('email', response.result.email);
                localStorage.setItem('user_groups', JSON.stringify(response.result.UserGroups));
                localStorage.setItem('permissions', JSON.stringify(response.result.Permissions));

                return response
            })
    },
    login: () => {
        localStorage.setItem('authenticated', true);
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem('authenticated');
        localStorage.removeItem('email');
        localStorage.removeItem('user_groups');
        localStorage.removeItem('permissions');
        logout();
        return Promise.resolve();
    },
    checkAuth: () => {
        if (getCookie('auth') === undefined ) {
            return Promise.reject();
        }

        if (localStorage.getItem('authenticated') === null) {
            return Promise.reject();
        }

        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('authenticated');
            localStorage.removeItem('email');
            localStorage.removeItem('user_groups');
            localStorage.removeItem('permissions');
            logout();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const getResourcesURL = `${apiPrefix}/permissions`;
        const request = new Request(getResourcesURL, {
            method: 'GET',
            credentials: 'include',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("Internal server error");
                }

                return response.json();
            })
            .then((response) => {
                if (response.status.code !== 200) {
                    throw new Error(response.status.reason);
                }

                localStorage.setItem('user_groups', JSON.stringify(response.result.UserGroups || []));
                localStorage.setItem('permissions', JSON.stringify(response.result.Permissions || []));

                const perm = {
                    'user_groups': response.result.UserGroups,
                    'permissions': response.result.Permissions,
                }

                return Promise.resolve(perm);
            })
    }
};

export default authProvider;