import React, {FunctionComponent} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    button: {
        color: '#005FF9',
        backgroundColor: 'rgba(0, 16, 61, 0.06)',
        lineHeight: '20px',
        fontSize: '15px',
        fontFamily: 'Arial',
        fontWeight: 400,
        fontStyle: 'normal',
        textTransform: 'none',
        boxShadow: 'none',
        marginRight: theme.spacing(2.5),
        padding: '10px 20px',
    },
}));

interface IUploadButtons {
    icon?: JSX.Element;
    text?: string;
    uploadFileHandler: (file: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadButtons: FunctionComponent<IUploadButtons> = ({
                                                              icon = "",
                                                              text = "",
                                                              uploadFileHandler
                                                          }) => {
    const classes = useStyles();

    return (
        <div>
            <input
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={uploadFileHandler}
            />
            <label htmlFor="contained-button-file">
                <Button startIcon={icon} variant="contained" component="span" className={classes.button}>
                    {text}
                </Button>
            </label>
        </div>
    );
}

export default UploadButtons;
