import React, { useState, useEffect } from 'react';
import { render } from "react-dom";
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import {AntivirusUploadKeyIcon, CheckBoxIcon, CheckBoxIconUnchecked} from "../MenuIcons/Icons";
import {CustomSwitch} from "./Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from '@material-ui/core/Divider';
import Question from "./Question";
import CustomTextField from "./TextField";
import UploadButtons from "./UploadButton";
import {checkValidityKey} from "../actions/actions";
import {ISafetyResponse} from "../types";
// import Alert from '@material-ui/lab/Alert';

const isIp = require('is-ip');
const isCidr = require("is-cidr");

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface SettingsTabProps {
    setTabChanged: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        network_tab: {
            '& .MuiTextField-root': {},
        },
        saveButton: {
            textTransform: 'none',
            color: '#FFFFFF',
            backgroundColor: '#005FF9',
            boxShadow: 'none',
            padding: '10px 17px',
        },
        cancelButton: {
            textTransform: 'none',
            color: '#333333',
            backgroundColor: '#F0F0F0',
            boxShadow: 'none',
            padding: '10px 17px',
        },
        header: {
            fontFamily: 'Arial',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '28px',
        },
        headerRoot: {
            paddingLeft: '0',
            paddingTop: '0',
            paddingBottom: '10px',
            marginLeft: '40px',
        },
        font: {
            fontSize: '15px',
            fontFamily: 'Arial',
            lineHeight: '20px',
        },
        formButtons: {
            '& > *': {
                marginRight: theme.spacing(1.5),
            }
        },
        antivirusKeyUploadButtonText: {
          marginLeft: '13px',
        },
        antivirusKeyLifetime: {
            color: "#0DC268",
        },
        antivirusKeyError: {
            color: "#ED0A34",
        },
        antivirusNoKey: {
            color: "#919399",
        },
        antivirusKeyTitle: {
            lineHeight: '20px',
            fontSize: '15px',
            fontFamily: 'Arial',
            fontWeight: 400,
            fontStyle: 'normal',
        },
        safetyFormTitle: {
            fontFamily: 'Arial',
            fontSize: '17px',
            lineHeight: '20px',
            letterSpacing: '0px',
            color: '#2C2D2E',
            fontWeight: 'bold',
            width: '100%',
        },
        inputTitle: {
            fontFamily: 'Arial',
            fontSize: '15px',
            lineHeight: '20px',
            letterSpacing: '0px',
            color: '#2C2D2E',
            fontWeight: 'bold',
        },
        tab: {
            textTransform: 'none',
            fontSize: '15px',
            lineHeight: '20px',
            minWidth: 'unset',
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: '40px',
        },
        tabSelected: {
          fontWeight: 'bold',
        },
        indicator: {
            backgroundColor: '#2469F5',
        },
        tabs: {
            borderBottom: '1px solid #EBEBEB',
        },
        container: {
            backgroundColor: '#ffffff',
            borderRadius: '8px 8px 0 0',
            marginTop: '20px',
            padding: '28px 0px 40px 0px',
        },
        formInput: {
            width: '380px',
            marginTop: '8px',
        },
        formMobileCheckbox: {
            marginBottom: '10px',
        },
        formMobileHeader: {
            paddingLeft: '12px',
            marginTop: '30px',
        },
        divider: {
            marginBottom: '24px',
            marginTop: '36px',
        },
        formLabel: {
            marginLeft: '-7px',
        },
        formHelperText: {
            color: '#919399',
            marginTop: '20px',
        },
        formControl: {
            minHeight: '573px',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
        },
    })
);

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pb={0} pt={4} p={5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const filterNetworks = (value: string) => {
    return value.split(/(?:\n)+|(?:,)+|(?:\s)+/).filter(e => {
        return e !== ""
    })
}

const validateNetworks = (networks: Array<string>): Array<string> => {
    const invalidNetworks: Array<string> = [];

    networks.forEach(v => {
        if (!isCidr.v4(v) && !isCidr.v6(v) && !isIp(v)) {
            invalidNetworks.push(v);
        }
    })

    return invalidNetworks;
}

const NetworkTab = (props: SettingsTabProps) => {
    const { setTabChanged, ...other } = props;
    const classes = useStyles();

    interface NetworkResponse {
        status: {
            code: number,
            reason: string,
        },
        result: {
            kspd: string,
            dmz: string,
        }
    }

    const removeExtraSymbols = (value: string) => {
        let tmpValue = value;
        tmpValue = tmpValue.replace(/(?:(\.){2,}|(\/){2,}|(,){2,})/ig, '$1$2$3');
        tmpValue = tmpValue.replace(/[^0-9.,/\t\n\s]+/ig, "");
        if (tmpValue.trim().length === 0) tmpValue = "";
        return tmpValue;
    }

    const [kspdValue, setKSPDValue] = React.useState('');
    const handleKSPDValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKSPDValue(removeExtraSymbols(event.target.value));
        setTabChanged(true);
    };
    const kspdValueErrorsInitial: Array<string> = [];
    const [kspdValueErrors, setKSPDValueErrors] = React.useState(kspdValueErrorsInitial);

    const [dmzValue, setDMZValue] = React.useState('');
    const handleDMZValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDMZValue(removeExtraSymbols(event.target.value));
        setTabChanged(true);
    };
    const dmzValueErrorsInitial: Array<string> = [];
    const [dmzValueErrors, setDMZValueErrors] = React.useState(kspdValueErrorsInitial);

    const [submitResponse, setSubmitResponse] = React.useState("")
    const [isSending, setIsSending] = React.useState(false)
    const submitNetworkTab = React.useCallback(async () => {
        if (isSending) {
            return
        }

        setIsSending(true);

        const kspdInvalidValues = validateNetworks(filterNetworks(kspdValue));
        if (kspdInvalidValues.length > 0) {
            setKSPDValueErrors(kspdInvalidValues);
        } else {
            setKSPDValueErrors(kspdValueErrorsInitial);
        }

        const dmzInvalidValues = validateNetworks(filterNetworks(dmzValue));
        if (dmzInvalidValues.length > 0) {
            setDMZValueErrors(dmzInvalidValues)
        } else {
            setDMZValueErrors(dmzValueErrorsInitial)
        }

        if (kspdInvalidValues.length == 0 && dmzInvalidValues.length == 0) {
            let formData = new FormData();
            formData.append("kspd", JSON.stringify(filterNetworks(kspdValue)))
            formData.append("dmz", JSON.stringify(filterNetworks(dmzValue)))

            const request = new Request(process.env.REACT_APP_API_HOST + '/api/settings/network', {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            fetch(request)
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error("Internal server error");
                    }

                    return response.json();
                }).then((response: NetworkResponse) => {
                if (response.status.code === 200) {
                    setSubmitResponse("Настройки успешно сохранены");
                    setTabChanged(false);
                } else {
                    setSubmitResponse("Во врем сохранения настроек произошла ошибка: " + response.status.reason);
                }
            })
        }

        setIsSending(false);
    }, [isSending, kspdValue, dmzValue])

    const isMounted = React.useRef(true);
    React.useEffect(() => {
        const request = new Request(process.env.REACT_APP_API_HOST + '/api/settings/network', {
            method: 'GET',
            credentials: 'include',
            headers: new Headers({'Content-Type': 'application/json'}),
        });

        fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("Internal server error");
                }

                return response.json();
            }).then((response: NetworkResponse) => {

            const kspdList = JSON.parse(response.result.kspd);
            if (kspdList !== null) {
                setKSPDValue(kspdList.join(","));
            }

            const dmzList = JSON.parse(response.result.dmz);
            if (dmzList !== null) {
                setDMZValue(dmzList.join(","));
            }
        })

        return () => {
            isMounted.current = false;
        };
    }, []);


    return (
        <form onSubmit={submitNetworkTab}>
            <Grid container className={`${classes.network_tab} ${classes.formControl}`}>
                <Grid container alignItems="center" justify="center" direction="column" style={{ width: "max-content" }}>
                    <Grid container spacing={5}>
                        <Grid item style={{ maxWidth: "420px", width: "420px" }}>
                            <CustomTextField
                                title="Зона корпоративной сети передачи данных"
                                placeholder="IP-адреса зоны"
                                value={kspdValue}
                                changeHandler={handleKSPDValueChange}
                                isError={kspdValueErrors.length > 0}
                                helperText={kspdValueErrors.length > 0
                                && `Неправильные значения: ${kspdValueErrors.join(', ')}` || ''} />
                        </Grid>
                        <Grid item style={{ maxWidth: "420px", width: "420px" }}>
                            <CustomTextField
                                title="Демилитаризованная зона"
                                placeholder="IP-адреса зоны"
                                value={dmzValue}
                                changeHandler={handleDMZValueChange}
                                isError={dmzValueErrors.length  > 0}
                                helperText={dmzValueErrors.length > 0
                                && `Неправильные значения: ${dmzValueErrors.join(', ')}` || ''} />
                        </Grid>
                    </Grid>
                    <Grid item >
                        <FormHelperText className={`${classes.font} ${classes.formHelperText}`} id="my-helper-text">Укажите адреса IP-адеса, разделяя их пробелом, запятой или новой строчкой</FormHelperText>
                    </Grid>
                </Grid>
                <Grid>
                    <Divider className={classes.divider} />
                    <div className={classes.formButtons}>
                        {
                            submitResponse.length > 0
                            && (
                                <Typography>
                                    Настройки успешно сохранены
                                </Typography>
                            )
                        }

                        <Button
                            name="submit"
                            className={classes.font}
                            variant="contained"
                            color="primary"
                            classes={{ root: classes.saveButton }}
                            onClick={submitNetworkTab}
                        >
                            Сохранить
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            // marginLeft: '-8px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            '&:disabled': {
                backgroundColor: 'rgba(0, 16, 61, 0.06)',
                borderColor: 'rgba(0, 16, 61, 0.12)',
                color: '#2C2D2E',
                opacity: '0.48',
            },
        },
    }),
)(InputBase);

enum AntivirusStatus {
    OK,
    ERROR,
    NO,
}

const SafetyTab = (props: SettingsTabProps) => {
    const { setTabChanged, ...other } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const [isShowMobile, toggleShowMobile] = useState(false); // удалить когда реализуем чекбоксы для мобил
    const [antivirusStatus, setAntivirusStatus] = useState(AntivirusStatus.NO)
    const antivirusInfo = React.useRef({ keyName: "", expiredDate: "", data: "" });

    useEffect(() => { // удалить когда реализуем чекбоксы для мобил
        // @ts-ignore
        window.toggleShowMobile = () => toggleShowMobile(!isShowMobile);
        return () => {
            // @ts-ignore
            window.toggleShowMobile = null;
        }
    });

    const [antivirusEnabled, setAntivirusEnabled] = React.useState(false);
    const handleAntivirusEnabled = (event: React.ChangeEvent<{value: unknown}>) => {
        setAntivirusEnabled((prev) => !prev);
        setTabChanged(true);
    };

    const [workMode, setWorkMode] = React.useState("async");
    const handleWorkMode = (event: React.ChangeEvent<{ value: unknown }>) => {
        setWorkMode(event.target.value as string);
        setTabChanged(true);
    };

    const [kspdWorkMode, setKSPDWorkMode] = React.useState("async");
    const handleKSPDWorkMode = (event: React.ChangeEvent<{ value: unknown }>) => {
        setKSPDWorkMode(event.target.value as string);
        setTabChanged(true);
    };

    const [dmzWorkMode, setDMZWorkMode] = React.useState("async");
    const handleDMZWorkMode = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDMZWorkMode(event.target.value as string);
        setTabChanged(true);
    };

    const isMounted = React.useRef(true);
    React.useEffect(() => {
        const request = new Request(process.env.REACT_APP_API_HOST + '/api/settings/safety', {
            method: 'GET',
            credentials: 'include',
            headers: new Headers({'Content-Type': 'application/json'}),
        });

        fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("Internal server error");
                }

                return response.json();
        }).then((response: ISafetyResponse) => {
            setAntivirusEnabled(response.result.antivirus_enabled);
            setWorkMode(response.result.work_mode);
            setKSPDWorkMode(response.result.kspd_work_mode);
            setDMZWorkMode(response.result.dmz_work_mode);
            if (response.result.key_name && response.result.expiration_date) {
                antivirusInfo.current.keyName = response.result.key_name;
                antivirusInfo.current.expiredDate = response.result.expiration_date;
                setAntivirusStatus(AntivirusStatus.OK)
            }
        })

        return () => {
            isMounted.current = false;
        };
    }, []);

    const [submitResponse, setSubmitResponse] = React.useState("")
    const [isSending, setIsSending] = React.useState(false)
    const submitSafetyTab = React.useCallback(async () => {
        if (isSending) {
            return
        }

        setIsSending(true);

        let formData = new FormData();
        formData.append("antivirus_enabled", antivirusEnabled ? "true" : "false")
        formData.append("work_mode", workMode)
        formData.append("kspd_work_mode", kspdWorkMode)
        formData.append("dmz_work_mode", dmzWorkMode)
        formData.append("key_name", antivirusInfo.current.keyName);
        formData.append("key_value", antivirusInfo.current.data);

        const request = new Request(process.env.REACT_APP_API_HOST + '/api/settings/safety', {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });

        fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("Internal server error");
                }

                return response.json();
            }).then((response: ISafetyResponse) => {
                if (response.status.code === 200) {
                    setSubmitResponse("Настройки успешно сохранены");
                    if (response.result.expiration_date) {
                        antivirusInfo.current.keyName = response.result.key_name;
                        antivirusInfo.current.expiredDate = response.result.expiration_date;
                        setAntivirusStatus(AntivirusStatus.OK)
                    }
                    setTabChanged(false);
                } else {
                    setSubmitResponse("Во время сохранения настроек произошла ошибка: " + response.status.reason);
                }
            })
        setIsSending(false);
    }, [isSending, antivirusEnabled, workMode, kspdWorkMode, dmzWorkMode])

    const uploadFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let encoded: string;
            encoded = reader.result as string;
            encoded = encoded.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }

            checkKey(file.name, encoded);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const checkKey = (keyName: string, keyValue: string) => {
        checkValidityKey(keyName, keyValue)
            .then((result) => {
                antivirusInfo.current.keyName = keyName;
                antivirusInfo.current.expiredDate = result.expiration_date;
                antivirusInfo.current.data = keyValue;
                setAntivirusStatus(AntivirusStatus.OK)
            })
            .catch(() => {
                setAntivirusStatus(AntivirusStatus.ERROR)
            })
    }

    const getAntivirusKeyStatus = (antivirusStatus: AntivirusStatus) => {
        let className = classes.antivirusNoKey;
        let text = "Еще не загружен";
        switch (antivirusStatus) {
            case AntivirusStatus.OK: {
                className = classes.antivirusKeyLifetime;
                const expireDate = new Date(antivirusInfo.current.expiredDate)
                const expireUnixTime = expireDate.getTime();
                const currentUnixTime = new Date().getTime();
                const delta = Math.abs(expireUnixTime - currentUnixTime) / 1000;
                const restDays = Math.floor(delta / 86400);
                text = `Активен еще ${restDays} ${declOfNum(restDays, ['день', 'дня', 'дней'])} (до ${expireDate.toLocaleDateString()})`;
                break;
            }
            case AntivirusStatus.ERROR: {
                className = classes.antivirusKeyError;
                text = "Некорректный файл ключа";
                break;
            }
            default: {
                break;
            }
        }

        return <Typography variant="body1" className={`${classes.antivirusKeyTitle} ${className}`}>{text}</Typography>
    }

    const declOfNum = (number: number, titles: string[]) => { //https://gist.github.com/realmyst/1262561
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    }

    return (
        <form onSubmit={submitSafetyTab}>
            <Grid container className={classes.formControl}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={10}>
                        <Typography variant="body1" className={classes.safetyFormTitle}>Антивирус
                            <Question title={`Kaspersky, версия SDK\nКупить ключ`} /></Typography>
                    </Grid>
                    <Grid container item xs={12} alignItems="center">
                        <UploadButtons icon={<AntivirusUploadKeyIcon />} text="Загрузить ключ" uploadFileHandler={uploadFileHandler}/>
                        {getAntivirusKeyStatus(antivirusStatus)}
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    name="avEnabled"
                                    color="primary"
                                    checked={antivirusEnabled && antivirusStatus === AntivirusStatus.OK}
                                    onChange={handleAntivirusEnabled}
                                />
                            }
                            className={classes.formLabel}
                            classes={{ label: classes.font }}
                            disabled={antivirusStatus !== AntivirusStatus.OK}
                            label="Проверять файлы антивирусом"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" className={classes.inputTitle}>
                            Проверка файлов из внешней сети
                        </Typography>
                        <FormControl fullWidth disabled={antivirusStatus !== AntivirusStatus.OK} >
                            <NativeSelect
                                id="work-mode" input={<BootstrapInput />}
                                value={workMode}
                                onChange={handleWorkMode}
                                className={classes.formInput}
                            >
                                <option value="async">Постпроверка</option>
                                <option value="sync">Предпроверка</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid container item spacing={5}>
                        <Grid item>
                            <Typography variant="body1" className={classes.inputTitle}>
                                Проверка файлов из КСПД<Question title="Корпоративная сеть передачи данных" />
                            </Typography>
                            <FormControl fullWidth disabled={antivirusStatus !== AntivirusStatus.OK}>
                                <NativeSelect
                                    id="kspd-work-mode" input={<BootstrapInput />}
                                    value={kspdWorkMode}
                                    onChange={handleKSPDWorkMode}
                                    className={classes.formInput}
                                >
                                    <option value="async">Постпроверка</option>
                                    <option value="sync">Предпроверка</option>
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className={classes.inputTitle}>
                                Проверка файлов из ДМЗ<Question title="Демилитаризованная зона" />
                            </Typography>
                            <FormControl fullWidth disabled={antivirusStatus !== AntivirusStatus.OK}>
                                <NativeSelect
                                    id="dmz-work-mode" input={<BootstrapInput />}
                                    value={dmzWorkMode}
                                    onChange={handleDMZWorkMode}
                                    className={classes.formInput}
                                >
                                    <option value="async">Постпроверка</option>
                                    <option value="sync">Предпроверка</option>
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {
                        isShowMobile ? // удалить когда реализуем чекбоксы для мобил
                            <>
                                <Typography variant="body1" className={classes.safetyFormTitle} classes={{ root: classes.formMobileHeader }}>Мобильные приложения</Typography>
                                <Grid container item direction="column">
                                    <FormControlLabel
                                        control={<Checkbox
                                            checkedIcon={CheckBoxIcon()}
                                            icon={CheckBoxIconUnchecked()}
                                            name="pinCode"
                                            color="primary" />
                                        }
                                        label="Вход через пин-код"
                                        className={classes.formMobileCheckbox}
                                        classes={{ root: classes.formLabel }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            checkedIcon={CheckBoxIcon()}
                                            icon={CheckBoxIconUnchecked()}
                                            name="osEncryption"
                                            color="primary" />
                                        }
                                        label="Шифрование хранилища на уровне ОС"
                                        classes={{ root: classes.formLabel }}
                                    />
                                </Grid></> :
                            ''
                    }
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider className={classes.divider} />
                    <FormControl fullWidth>
                        {
                            submitResponse.length > 0
                            && (
                                <Typography>
                                    Настройки успешно сохранены
                                </Typography>
                            )
                        }
                        <div className={classes.formButtons}>
                            <Button
                                name="submit"
                                className={classes.font}
                                variant="contained"
                                color="primary"
                                classes={{ root: classes.saveButton }}
                                onClick={submitSafetyTab}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </FormControl>
                </Grid>
            </Grid>

        </form>
    )
}

const Settings = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();

    const [tabContentChanged, setTabContentChanged] = React.useState(false);

    const [tabValue, setTabValue] = React.useState(0);
    const handleSwitchTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (tabContentChanged) {
            let answer = window.confirm("У вас есть несохраненные изменения, забыть о них и перейти в другой раздел? ");
            if (answer) {
                setTabContentChanged(false);
                setTabValue(newValue);
                return
            }
            return
        }

        setTabValue(newValue);
    };

    return (
        <Grid container className={classes.container}>
            <Grid item style={{ width: "100%" }}>
                <CardHeader classes={{ title: classes.header, root: classes.headerRoot }} title="Конфигурация" />

                <Tabs value={tabValue} onChange={handleSwitchTab} aria-label="simple tabs example" classes={{ indicator: classes.indicator, root: classes.tabs }}>
                    {/*<Tab classes={{ selected: classes.tabSelected }} className={classes.tab} label={translate("settings.tabs.Profile.name")}*/}
                    {/*     {...a11yProps(0)} disabled />*/}
                    {/*<Tab classes={{ selected: classes.tabSelected }} className={classes.tab} label={translate("settings.tabs.VIPUsers.name")}*/}
                    {/*     {...a11yProps(1)} disabled />*/}
                    <Tab classes={{ selected: classes.tabSelected }} className={classes.tab} label={translate("settings.tabs.Safety.name")}
                         {...a11yProps(0)} />
                    <Tab classes={{ selected: classes.tabSelected }} className={classes.tab} label={translate("settings.tabs.Network.name")}
                         {...a11yProps(1)} />
                </Tabs>

                {/*<TabPanel value={tabValue} index={0}>*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={tabValue} index={1}>*/}
                {/*</TabPanel>*/}

                <TabPanel value={tabValue} index={0}>
                    <SafetyTab setTabChanged={setTabContentChanged} />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <NetworkTab setTabChanged={setTabContentChanged} />
                </TabPanel>

            </Grid>
        </Grid>
    );
}

export default Settings;
