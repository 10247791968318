import * as React from 'react';
import { List } from 'react-admin';
import 'react-datepicker/dist/react-datepicker.css';

import {
    Box,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: 140,
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    iframe: {
        border: 0,
        minHeight: '90vh',
        width: '100%',
    },
});

// const GraphGrid: FC<ListControllerProps<Graph>> = props => {
//     const classes = useStyles(props);
//     const { data, ids } = props;
//
//     if (!ids) {
//         return null;
//     }
//
//     return <Grid container className={classes.root} spacing={2}>
//         {
//             ids.map(id => {
//                 return <Grid key={id} xs={6} item>
//                     <Card className="card">
//                         <CardMedia
//                             component="iframe"
//                             title={`${data[id].id}`}
//                             src={`${data[id].url}`}
//                             className={`${classes.iframe}`}
//
//                         />
//                         <CardContent>
//                             {
//                                 data[id].description.map((foo: React.ReactNode) => {
//                                     return <Typography variant="body2" color="textSecondary" component="p">
//                                         — {foo}
//                                     </Typography>
//                                 })
//                             }
//                         </CardContent>
//                     </Card>
//                 </Grid>;
//             })}
//     </Grid>;
// };

// function updateGrafanaIframeSrc(start: Date | null, end: Date | null) {
//     let grafanaIframeList = Array.from(document.querySelectorAll("iframe"));
//     if (!grafanaIframeList) {
//         return
//     }
//
//     for (const iframe of grafanaIframeList) {
//         const [iframeURL, querystring] = iframe.src.split("?");
//         if (!querystring) {
//             return
//         }
//
//         const iframeQueryParams = new URLSearchParams(querystring);
//         if (start) {
//             iframeQueryParams.set('from', String(+start));
//         }
//         if (end) {
//             iframeQueryParams.set('to', String(+end));
//         }
//
//         iframe.src = iframeURL + "?" + iframeQueryParams.toString();
//     }
// }

// const GraphList1 = (props: any) => {
//     let startDate: Date, setStartDate: (value: (((prevState: Date) => Date) | Date)) => void;
//     [startDate, setStartDate] = useState(new Date(moment().subtract(7, 'days').toISOString()));
//
//     let endDate: Date, setEndDate: (value: (((prevState: Date) => Date) | Date)) => void;
//     [endDate, setEndDate] = useState(new Date());
//
//     let today = moment();
//
//     let presetList = () => {
//         const presets = [
//             {
//                 text: 'Last 5 minutes',
//                 end: today,
//                 start: moment().subtract(5, 'minutes'),
//             },
//             {
//                 text: 'Last 15 minutes',
//                 end: today,
//                 start: moment().subtract(15, 'minutes'),
//             },
//             {
//                 text: 'Last 30 minutes',
//                 end: today,
//                 start: moment().subtract(30, 'minutes'),
//             },
//             {
//                 text: 'Last 1 hour',
//                 end: today,
//                 start: moment().subtract(1, 'hours'),
//             },
//             {
//                 text: 'Last 3 hours',
//                 end: today,
//                 start: moment().subtract(3, 'hours'),
//             },
//             {
//                 text: 'Last 6 hours',
//                 end: today,
//                 start: moment().subtract(6, 'hours'),
//             },
//             {
//                 text: 'Last 12 hours',
//                 end: today,
//                 start: moment().subtract(12, 'hours'),
//             },
//             {
//                 text: 'Last 24 hours',
//                 end: today,
//                 start: moment().subtract(24, 'hours'),
//             },
//             {
//                 text: 'Last 2 days',
//                 end: today,
//                 start: moment().subtract(2, 'days'),
//             },
//             {
//                 text: 'Last 7 days',
//                 end: today,
//                 start: moment().subtract(7, 'days'),
//             },
//             {
//                 text: 'Last 30 days',
//                 end: today,
//                 start: moment().subtract(30, 'days'),
//             },
//             {
//                 text: 'Last 90 days',
//                 end: today,
//                 start: moment().subtract(90, 'days'),
//             },
//             {
//                 text: 'Last 6 months',
//                 end: today,
//                 start: moment().subtract(6, 'months'),
//             },
//             {
//                 text: 'Last 1 year',
//                 end: today,
//                 start: moment().subtract(1, 'years'),
//             },
//             {
//                 text: 'Last 2 years',
//                 end: today,
//                 start: moment().subtract(2, 'years'),
//             },
//             {
//                 text: 'Last 5 years',
//                 end: today,
//                 start: moment().subtract(5, 'years'),
//             },
//             {
//                 text: 'Last 10 years',
//                 end: today,
//                 start: moment().subtract(10, 'years'),
//             },
//         ];
//
//         return presets.map(obj => {
//             return <Button
//                 label={obj.text}
//                 onClick={() => {
//                     const start = new Date(obj.start.toISOString());
//                     const end = new Date(obj.end.toISOString())
//                     setStartDate(start);
//                     setEndDate(end);
//                     updateGrafanaIframeSrc(start, end);
//                 }}
//             />
//         });
//     };
//
//     return (
//         <Grid container justify="center" spacing={2}>
//             <Grid item>
//                 <DatePicker
//                     dateFormat="yyyy-MM-dd hh:mm:ss"
//                     showYearDropdown
//                     selected={startDate}
//                     onChange={(date: React.SetStateAction<Date>) => {
//                         setStartDate(date);
//                         updateGrafanaIframeSrc(date as Date, null);
//                     }}
//                     selectsStart
//                     startDate={startDate}
//                     endDate={endDate}
//                 />
//             </Grid>
//             <Grid item>
//                 <DatePicker
//                     dateFormat="yyyy-MM-dd hh:mm:ss"
//                     showYearDropdown
//                     selected={endDate}
//                     onChange={(date: React.SetStateAction<Date>) => {
//                         setEndDate(date);
//                         updateGrafanaIframeSrc(null, date as Date);
//                     }}
//                     selectsEnd
//                     startDate={startDate}
//                     endDate={endDate}
//                     minDate={startDate}
//                 />
//             </Grid>
//             <Grid >
//                 {presetList()}
//             </Grid>
//
//             <List
//                 {...props}
//                 sort={{field: 'name', order: 'ASC'}}
//                 perPage={20}
//                 pagination={false}
//                 component="div"
//                 actions={false}
//             >
//
//                 {/*
//                 // @ts-ignore */}
//                 <GraphGrid/>
//             </List>
//         </Grid>
//     );
// };


// const GraphGrid: FC<ListControllerProps<Graph>> = props => {
//     const classes = useStyles(props);
//     const { data, ids } = props;
//
//     if (!ids) {
//         return null;
//     }
//
//     return (
//         <Box {...props} minHeight="900" width="auto">
//             <Title title={"resources." + translate("Analytics") + ".name"} />
//             <iframe
//                 title="Analytics"
//                 className={`${classes.iframe}`}
//                 src={grafanaURLPrefix + "/d/5xDTOmGMk/new-dashboard-copy?orgId=1&from=now-3M&to=now-1M&kiosk=tv"}
//             />
//         </Box>
//     )
//
//     return <Grid container className={classes.root} spacing={2}>
//         {
//             ids.map(id => {
//                 return <Grid key={id} xs={6} item>
//                     <Card className="card">
//                         <CardMedia
//                             component="iframe"
//                             title={`${data[id].id}`}
//                             src={`${data[id].url}`}
//                             className={`${classes.iframe}`}
//
//                         />
//                         <CardContent>
//                             {
//                                 data[id].description.map((foo: React.ReactNode) => {
//                                     return <Typography variant="body2" color="textSecondary" component="p">
//                                         — {foo}
//                                     </Typography>
//                                 })
//                             }
//                         </CardContent>
//                     </Card>
//                 </Grid>;
//             })}
//     </Grid>;
// };


const Dashboard = (props: any) => {
    const classes = useStyles(props);
    const { data, ids } = props;

    if (!ids) {
        return null;
    }

    return ids.map((id: any) => {
        return <Box {...props} minHeight="900" width="auto">
            <h1>{props.defaultTitle}</h1>
            <iframe
                title={`${data[id].url}`}
                className={`${classes.iframe}`}
                src={`${data[id].url}`}
            />
        </Box>
    });
}


const  GraphList = (props: any) => {
    return (
        <List title={<span></span>} {...props} pagination={false} component="div" actions={false}>
            <Dashboard/>
        </List>
    )
}

export default GraphList;
