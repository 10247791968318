import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        fontSize: '15px'
    },
    icon: {
        fontSize: '20px',
        width: '24px',
        marginRight: '6px',
    }
});
const LogoutButton = forwardRef((props, ref) => {
    const classes = useStyles();
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItem
            className={classes.root}
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon className={classes.icon}/> Выйти
        </MenuItem>
    );
});

export default LogoutButton;