import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';


export function MyteamLogo(props) {
    return (<SvgIcon {...props} width="111" height="28" viewBox="0 0 111 28" fill="none">
        <path
            d="M35.6277 9.55859H37.6553V10.4662C37.99 10.0928 38.3955 9.80962 38.8718 9.61652C39.361 9.41054 39.8824 9.30756 40.436 9.30756C41.0668 9.30756 41.6396 9.44273 42.1546 9.71307C42.6695 9.97054 43.1137 10.3439 43.487 10.8331C43.8989 10.3439 44.401 9.97054 44.9932 9.71307C45.5854 9.44273 46.2226 9.30756 46.9049 9.30756C47.5229 9.30756 48.0893 9.41698 48.6042 9.63583C49.1192 9.85468 49.5569 10.1636 49.9173 10.5627C50.2778 10.9489 50.5546 11.4188 50.7477 11.9724C50.9537 12.5131 51.0567 13.1117 51.0567 13.7682V19.4069H49.0291V13.7876C49.0291 13.028 48.8231 12.423 48.4111 11.9724C48.0121 11.5089 47.4714 11.2772 46.7891 11.2772C46.4415 11.2772 46.1196 11.3416 45.8235 11.4703C45.5275 11.5862 45.27 11.7535 45.0511 11.9724C44.8323 12.1912 44.6585 12.4487 44.5298 12.7448C44.4139 13.0409 44.356 13.3627 44.356 13.7103V19.4069H42.3284V13.7876C42.3284 13.028 42.1224 12.423 41.7104 11.9724C41.3114 11.5089 40.7707 11.2772 40.0884 11.2772C39.7408 11.2772 39.4189 11.3416 39.1229 11.4703C38.8268 11.5862 38.5693 11.7535 38.3504 11.9724C38.1316 12.1912 37.9578 12.4487 37.8291 12.7448C37.7132 13.0409 37.6553 13.3627 37.6553 13.7103V19.4069H35.6277V9.55859Z"
            fill="white"/>
        <path
            d="M55.8173 19.1751L51.6462 9.55859H53.8862L56.9373 16.6648L59.8531 9.55859H62.0352L56.3966 22.8827H54.2145L55.8173 19.1751Z"
            fill="white"/>
        <path
            d="M68.0185 19.4069C66.8599 19.4069 65.9716 19.1236 65.3537 18.5572C64.7486 17.9908 64.4461 17.1604 64.4461 16.0662V11.451H62.6116V9.55859H64.4461V5.50342H66.4737V9.55859H69.5634V11.451H66.4737V16.0662C66.4737 16.5554 66.6089 16.9158 66.8792 17.1476C67.1624 17.3664 67.613 17.4758 68.2309 17.4758H69.5634V19.4069H68.0185Z"
            fill="white"/>
        <path
            d="M75.7911 19.6579C75.0573 19.6579 74.3686 19.5227 73.7249 19.2524C73.0812 18.982 72.5212 18.6151 72.0449 18.1517C71.5814 17.6882 71.2145 17.1411 70.9442 16.5103C70.6739 15.8795 70.5387 15.2036 70.5387 14.4827C70.5387 13.7618 70.6674 13.0859 70.9249 12.4551C71.1952 11.8243 71.5621 11.2772 72.0256 10.8138C72.489 10.3503 73.0297 9.98342 73.6476 9.71307C74.2784 9.44273 74.9543 9.30756 75.6752 9.30756C76.3961 9.30756 77.0656 9.44273 77.6835 9.71307C78.3143 9.98342 78.8614 10.3503 79.3249 10.8138C79.7883 11.2772 80.1488 11.8308 80.4063 12.4745C80.6766 13.1053 80.8118 13.7876 80.8118 14.5213C80.8118 14.7917 80.7925 15.1071 80.7538 15.4676H72.6821C72.8881 16.1241 73.2679 16.6584 73.8214 17.0703C74.3879 17.4823 75.083 17.6882 75.907 17.6882C76.5635 17.6882 77.1428 17.5595 77.6449 17.302C78.147 17.0446 78.5718 16.7292 78.9194 16.3558L80.2518 17.6882C79.6725 18.3191 78.9966 18.8082 78.2242 19.1558C77.4518 19.4905 76.6407 19.6579 75.7911 19.6579ZM78.7842 13.6138C78.6168 12.88 78.2564 12.3007 77.7028 11.8758C77.1493 11.451 76.4927 11.2386 75.7332 11.2386C74.9736 11.2386 74.3106 11.451 73.7442 11.8758C73.1906 12.3007 72.8302 12.88 72.6628 13.6138H78.7842Z"
            fill="white"/>
        <path
            d="M86.9701 19.6579C86.2492 19.6579 85.5798 19.5292 84.9618 19.2717C84.3439 19.0013 83.8032 18.6345 83.3398 18.171C82.8892 17.7076 82.5287 17.1604 82.2584 16.5296C82.0009 15.8988 81.8722 15.2165 81.8722 14.4827C81.8722 13.7489 82.0009 13.0666 82.2584 12.4358C82.5287 11.805 82.8892 11.2579 83.3398 10.7945C83.8032 10.331 84.3439 9.97054 84.9618 9.71307C85.5798 9.44273 86.2492 9.30756 86.9701 9.30756C87.6524 9.30756 88.2704 9.42986 88.8239 9.67445C89.3904 9.90618 89.8795 10.228 90.2915 10.64V9.55859H92.3191V19.4069H90.2915V18.3255C89.8795 18.7374 89.3904 19.0657 88.8239 19.3103C88.2704 19.542 87.6524 19.6579 86.9701 19.6579ZM87.1439 17.6882C87.6074 17.6882 88.0322 17.611 88.4184 17.4565C88.8046 17.2892 89.1393 17.0639 89.4225 16.7807C89.7058 16.4974 89.9246 16.1627 90.0791 15.7765C90.2336 15.3774 90.3108 14.9462 90.3108 14.4827C90.3108 14.0193 90.2336 13.5945 90.0791 13.2082C89.9246 12.8092 89.7058 12.468 89.4225 12.1848C89.1393 11.9016 88.8046 11.6827 88.4184 11.5282C88.0322 11.3609 87.6074 11.2772 87.1439 11.2772C86.6933 11.2772 86.2749 11.3609 85.8887 11.5282C85.5025 11.6827 85.1678 11.9016 84.8846 12.1848C84.6014 12.468 84.3825 12.8092 84.2281 13.2082C84.0736 13.5945 83.9963 14.0193 83.9963 14.4827C83.9963 14.9462 84.0736 15.3774 84.2281 15.7765C84.3825 16.1627 84.6014 16.4974 84.8846 16.7807C85.1678 17.0639 85.5025 17.2892 85.8887 17.4565C86.2749 17.611 86.6933 17.6882 87.1439 17.6882Z"
            fill="white"/>
        <path
            d="M94.6336 9.55859H96.6612V10.4662C96.9959 10.0928 97.4014 9.80962 97.8777 9.61652C98.3669 9.41054 98.8883 9.30756 99.4419 9.30756C100.073 9.30756 100.646 9.44273 101.161 9.71307C101.675 9.97054 102.12 10.3439 102.493 10.8331C102.905 10.3439 103.407 9.97054 103.999 9.71307C104.591 9.44273 105.229 9.30756 105.911 9.30756C106.529 9.30756 107.095 9.41698 107.61 9.63583C108.125 9.85468 108.563 10.1636 108.923 10.5627C109.284 10.9489 109.561 11.4188 109.754 11.9724C109.96 12.5131 110.063 13.1117 110.063 13.7682V19.4069H108.035V13.7876C108.035 13.028 107.829 12.423 107.417 11.9724C107.018 11.5089 106.477 11.2772 105.795 11.2772C105.447 11.2772 105.126 11.3416 104.829 11.4703C104.533 11.5862 104.276 11.7535 104.057 11.9724C103.838 12.1912 103.664 12.4487 103.536 12.7448C103.42 13.0409 103.362 13.3627 103.362 13.7103V19.4069H101.334V13.7876C101.334 13.028 101.128 12.423 100.716 11.9724C100.317 11.5089 99.7766 11.2772 99.0943 11.2772C98.7467 11.2772 98.4249 11.3416 98.1288 11.4703C97.8327 11.5862 97.5752 11.7535 97.3564 11.9724C97.1375 12.1912 96.9637 12.4487 96.835 12.7448C96.7191 13.0409 96.6612 13.3627 96.6612 13.7103V19.4069H94.6336V9.55859Z"
            fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M1.98574 21.1871C0.725019 19.0857 0 16.6269 0 14C0 6.27317 6.27317 0 14 0C21.7268 0 28 6.27317 28 14C28 21.7268 21.7268 28 14 28C11.9123 28 9.93061 27.5421 8.15017 26.7211L3.29732 27.4867C3.22596 27.4993 3.15271 27.5058 3.0781 27.5058C3.04712 27.5058 3.01641 27.5047 2.98588 27.5024L2.98056 27.5021L2.97938 27.502C2.30819 27.4502 1.79037 26.877 1.81368 26.1978L1.98574 21.1871ZM8.95603 24.3037C10.4786 25.0511 12.1907 25.471 14 25.471C20.331 25.471 25.471 20.331 25.471 14C25.471 7.66902 20.331 2.52903 14 2.52903C7.66902 2.52903 2.52903 7.66902 2.52903 14C2.52903 16.2526 3.17972 18.3544 4.30324 20.1275C4.45254 20.3433 4.53618 20.6071 4.52652 20.8892L4.39383 24.7534L8.14746 24.1613C8.43649 24.1157 8.71821 24.172 8.95603 24.3037Z"
              fill="url(#paint0_linear)"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.0595 9.73438C16.1296 9.73438 16.9982 10.6032 16.9982 11.6732V12.2742L18.987 10.5017C19.2085 10.3042 19.5252 10.2553 19.7959 10.3767C20.0667 10.4981 20.241 10.767 20.241 11.0637C20.241 12.7314 20.241 15.2732 20.241 16.9409C20.241 17.2376 20.0667 17.5065 19.7959 17.6279C19.5252 17.7493 19.2085 17.7004 18.987 17.5029L16.9982 15.7304V16.3314C16.9982 17.4015 16.1295 18.2702 15.0595 18.2702H10.4016C9.33152 18.2702 8.46289 17.4015 8.46289 16.3314V11.6732C8.46289 10.6032 9.33152 9.73438 10.4016 9.73438H15.0595Z"
              fill="url(#paint1_linear)"/>
        <defs>
            <linearGradient id="paint0_linear" x1="2.13593" y1="27.0849" x2="22.0238" y2="2.52903"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#FF952E"/>
                <stop offset="0.43" stopColor="#F04886"/>
                <stop offset="1" stopColor="#7747FF"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="7.67672" y1="20.4152" x2="17.6122" y2="9.73438"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#00FF58"/>
                <stop offset="1" stopColor="#00D8FF"/>
            </linearGradient>
        </defs>
    </SvgIcon>)
}

export const DashboardIcon = () => {
    return <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8 0C12.415 0 16 3.585 16 8C16 12.415 12.415 16 8 16C3.585 16 0 12.415 0 8C0 3.585 3.585 0 8 0ZM9.331 12C9.881 12 10.327 11.554 10.327 11.004C10.327 10.454 9.881 10.007 9.331 10.007H8.998V6.757C8.998 6.314 8.759 6 8.318 6C8.315 6 6.683 6 6.683 6C6.129 6 5.678 6.45 5.678 7.005C5.678 7.56 6.129 8.01 6.683 8.01H6.995V10.007H6.654C6.104 10.007 5.657 10.454 5.657 11.004C5.657 11.554 6.104 12 6.654 12H9.331ZM8 2.658C8.741 2.658 9.342 3.259 9.342 4C9.342 4.741 8.741 5.342 8 5.342C7.259 5.342 6.658 4.741 6.658 4C6.658 3.259 7.259 2.658 8 2.658Z"
              fill="#333333"/>
    </SvgIcon>
}
export const AnalyticsIcon = () => {
    return <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M4.00392 6.00378H2.00391C1.45162 6.00378 1.00391 6.4515 1.00391 7.00378V14.0038C1.00391 14.5561 1.45162 15.0038 2.00391 15.0038H4.00392C4.5562 15.0038 5.00392 14.5561 5.00392 14.0038V7.00378C5.00392 6.4515 4.5562 6.00378 4.00392 6.00378Z"
            fill="#333333"/>
        <path
            d="M9.00392 2.00378H7.00391C6.45162 2.00378 6.00391 2.65048 6.00391 3.44823V13.5593C6.00391 14.3571 6.45162 15.0038 7.00391 15.0038H9.00392C9.5562 15.0038 10.0039 14.3571 10.0039 13.5593V3.44823C10.0039 2.65048 9.5562 2.00378 9.00392 2.00378Z"
            fill="#333333"/>
        <path
            d="M14.002 9.00391H12.002C11.4497 9.00391 11.002 9.30238 11.002 9.67057V14.3372C11.002 14.7054 11.4497 15.0039 12.002 15.0039H14.002C14.5542 15.0039 15.002 14.7054 15.002 14.3372V9.67057C15.002 9.30238 14.5542 9.00391 14.002 9.00391Z"
            fill="#333333"/>
    </SvgIcon>
}
export const ExportIcon = () => {
    return <SvgIcon width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.7426 8.37853L9.07134 13.409C8.82164 13.6542 8.44709 13.8994 7.9477 13.8994C7.58522 13.8994 7.30318 13.7056 7.08029 13.5525C6.9961 13.4946 6.92035 13.4426 6.85189 13.409L2.10476 8.31351L1.97991 8.1909C1.85506 7.94568 1.73021 7.70045 1.73021 7.45523C1.73021 6.84218 2.22961 6.35174 2.85386 6.35174H5.47565L5.45938 1.50784C5.45938 0.89479 6.08363 0.281739 6.70788 0.281739L9.28491 0.25C9.90916 0.25 10.5334 0.863052 10.5334 1.4761V6.35174L13.1184 6.41677C13.7426 6.41677 14.242 6.90721 14.242 7.52026C14.242 7.76548 13.9923 8.13331 13.7426 8.37853ZM1.75 15.25C1.05964 15.25 0.5 15.8096 0.5 16.5C0.5 17.1904 1.05964 17.75 1.75 17.75H14.25C14.9404 17.75 15.5 17.1904 15.5 16.5C15.5 15.8096 14.9404 15.25 14.25 15.25H1.75Z"
              fill="#333333"/>
    </SvgIcon>
}

export const CheckBoxIcon = () => {
    return <SvgIcon style={{ width: '16px', height: '16px' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="#005FF9"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.7 5.3C11.3 4.9 10.7 4.9 10.3 5.3L7 8.6L5.7 7.3C5.3 6.9 4.7 6.9 4.3 7.3C3.9 7.7 3.9 8.3 4.3 8.7L6.3 10.7C6.5 10.9 6.7 11 7 11C7.3 11 7.5 10.9 7.7 10.7L11.7 6.7C12.1 6.3 12.1 5.7 11.7 5.3Z" fill="white"/>
    </SvgIcon>

}

export const CheckBoxIconUnchecked = () => {
    return <SvgIcon style={{ width: '16px', height: '16px' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.65 4C0.65 2.14985 2.14985 0.65 4 0.65H12C13.8501 0.65 15.35 2.14985 15.35 4V12C15.35 13.8501 13.8501 15.35 12 15.35H4C2.14985 15.35 0.65 13.8501 0.65 12V4Z" fill="white" stroke="#00103D" strokeOpacity="0.16" strokeWidth="1.3"/>
    </SvgIcon>
}

 export const AntivirusUploadKeyIcon = () => {
     return <SvgIcon style={{ width: '15px', height: '15px' }} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M4.403 7.056L4.227 6.88C3.349 6.002 3.349 4.576 4.227 3.698L6.172 1.754C7.733 0.193002 10.267 0.193002 11.828 1.754L13.243 3.168C14.804 4.729 14.804 7.264 13.243 8.825L11.298 10.769C10.42 11.647 8.994 11.647 8.116 10.769L7.939 10.592L6.707 11.823C6.576 11.954 6.299 12.214 5.865 12.214C5.055 12.214 3.892 11.967 3.646 12.214C3.384 12.473 3.494 12.869 3.496 13.995C3.515 14.638 2.765 14.995 2 14.995H1C0.341 14.995 0 14.7 0 13.995V11.977C0 11.657 0.122 11.337 0.366 11.094L4.403 7.056ZM11.405 6.279C11.795 5.889 11.795 5.255 11.405 4.865L10.132 3.591C9.741 3.201 9.108 3.201 8.718 3.591C8.327 3.981 8.327 4.615 8.718 5.005L9.991 6.279C10.381 6.669 11.015 6.669 11.405 6.279Z" fill="#005FF9"/>
     </SvgIcon>
 }

 export const QuestionIcon = () => {
    return <SvgIcon style={{ width: '12px', height: '12px' }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6 0C9.31125 0 12 2.68875 12 6C12 9.31125 9.31125 12 6 12C2.68875 12 0 9.31125 0 6C0 2.68875 2.68875 0 6 0ZM5.223 8.6085C5.223 9.03075 5.5665 9.37425 5.98875 9.37425C6.411 9.37425 6.7545 9.03075 6.7545 8.6085C6.7545 8.18625 6.411 7.84275 5.98875 7.84275C5.5665 7.84275 5.223 8.18625 5.223 8.6085ZM6.087 2.64075C4.791 2.64075 4.044 3.7305 4.044 4.26075C4.044 4.49625 4.17225 4.86975 4.5945 4.86975C4.9185 4.86975 5.1345 4.63425 5.14425 4.428C5.1735 4.22175 5.3895 3.75 6.048 3.75C6.519 3.75 6.951 4.08375 6.951 4.49625C6.951 5.32125 5.34075 5.38725 5.34075 6.60525C5.34075 7.01775 5.62575 7.3125 5.979 7.3125C6.35175 7.3125 6.63675 7.01775 6.63675 6.64425C6.63675 5.98425 8.1885 5.88075 8.1885 4.467C8.1885 3.43575 7.236 2.64075 6.087 2.64075Z" fill="#BFC1C7"/>
    </SvgIcon>
 }
