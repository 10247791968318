import React, {FunctionComponent} from 'react';
import Typography from "@material-ui/core/Typography";
import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import {createStyles, makeStyles, withStyles} from "@material-ui/core/styles";

interface ITextField {
    title: string;
    value: string;
    changeHandler: TextFieldProps['onChange'];
    isError: boolean;
    helperText: string;
    placeholder: string;
    height?: number;
    width?: number;
}

const padding = 37;
const defaultHeight = 320;

const useStyles = makeStyles(() =>
    createStyles({
        header: {
            fontFamily: 'Arial',
            fontSize: '15px',
            lineHeight: '20px',
            letterSpacing: '0px',
            color: '#2C2D2E',
            fontWeight: 'bold',
            marginBottom: '8px',
        },
        field: {
            borderColor: 'rgba(0, 0, 0, 0.12)',
        },
    })
);

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.12)',
            },
            '&:hover fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.2)',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#005FF9',
            },
        },
    },
})(TextField);

const CustomTextField: FunctionComponent<ITextField> = ({
                                                            title = '',
                                                            value = '',
                                                            changeHandler,
                                                            isError,
                                                            helperText = '',
                                                            placeholder = '',
                                                            height = defaultHeight,
                                                        }) => {
    const classes = useStyles();

    return <>
        {title ? <Typography variant="body1" className={classes.header}>{title}
        </Typography> : ''}
        <CssTextField
            id="outlined-textarea"
            placeholder={placeholder}
            multiline
            variant="outlined"
            fullWidth
            rows={20}
            value={value}
            onChange={changeHandler}
            error={isError}
            helperText={helperText}
            style={{height}}
            inputProps={{
                style: {height: height - padding},
            }}
        />
    </>;
}

export default CustomTextField;
