import {ICheckValidityKey} from '../types';

export const checkValidityKey = (keyName: string, keyValue: string) => {
    let formData = new FormData();
    formData.append("key_name", keyName);
    formData.append("key_value", keyValue);

    return fetch(`${process.env.REACT_APP_API_HOST}/api/settings/safety/key`, {
        method: "POST",
        body: formData,
        credentials: 'include',
    })
        .then((response) => {
            if (response.status !== 200) {
                throw new Error("Internal server error");
            }
            return response.json();
        })
        .then((response: ICheckValidityKey) => {
            return response.result;
        })
        .catch((error) => {
            return error
        })
}
