import russianMessages from 'ra-language-russian';
import englishMessages from 'ra-language-english';

let CustomMessages = {
    ru: {
        ...russianMessages,
    },
    en: {
        ...englishMessages,
    }
};

CustomMessages['ru']['resources'] = {
    Information: {
      name: 'Информация'
    },
    Users: {
        name: 'Пользователи'
    },
    Analytics: {
        name: 'Аналитика'
    },
    Export: {
        name: 'Выгрузка'
    },
    Settings: {
        name: 'Конфигурация'
    },
    Management: {
        name: 'Управление'
    },
};

CustomMessages['en']['resources'] = {
    Information: {
        name: 'Information'
    },
    Users: {
        name: 'Users'
    },
    Analytics: {
        name: 'Analytics'
    },
    Settings: {
        name: 'Settings'
    },
    Management: {
        name: 'Management'
    },
};

CustomMessages['en']['settings'] = {'tabs': {}};
CustomMessages['ru']['settings'] = {'tabs': {}};

CustomMessages['en']['settings']['tabs'] = {
    Profile: {
        name: 'Profile'
    },
    VIPUsers: {
        name: 'VIP users'
    },
    Safety: {
        name: 'Safety'
    },
    Network: {
        name: 'Network'
    },
};

CustomMessages['ru']['settings']['tabs'] = {
    Profile: {
        name: 'Профиль'
    },
    VIPUsers: {
        name: 'VIP-пользователи'
    },
    Safety: {
        name: 'Безопасность'
    },
    Network: {
        name: 'Сеть'
    },
};

CustomMessages['en']['management'] = {'searchResult': {}};
CustomMessages['ru']['management'] = {'searchResult': {}};

CustomMessages['en']['management']['searchResult'] = {
    Name: {
        name: 'Name'
    },
    Stamp: {
        name: 'Chat-ID'
    },
    Type: {
        name: 'Type'
    },
    Members: {
        name: 'Members'
    },
    Search: {
        name: 'Search'
    },
    Channels: {
        name: 'Channels'
    },
    Groups: {
        name: 'Groups'
    },
};

CustomMessages['ru']['management']['searchResult'] = {
    Name: {
        name: 'Название'
    },
    Stamp: {
        name: 'ID Группы'
    },
    Type: {
        name: 'Тип'
    },
    Members: {
        name: 'Участники'
    },
    Search: {
        name: 'Поиск'
    },
    Channels: {
        name: 'Каналы'
    },
    Groups: {
        name: 'Группы'
    },
};

export const Messages = CustomMessages;
