import { stringify } from 'query-string';

const urlPrefix = process.env.REACT_APP_API_HOST + '/api';

const GetListResourceMap = new Map([
    ['Users', '/search'],
    ['Analytics', '/analytics'],
    ['Management', '/chats/search'],
]);

const godmod = {
    update: (resource, params) => {
        const url = `${urlPrefix}/user/edit`;
        const formData = new FormData();
        formData.append('file', params.data.pictures.rawFile);
        formData.append('sn', params.id)
        const request = new Request(url, {
            method: 'post',
            body: formData,
            credentials: "include"
        });
        return fetch(request, {
            credentials: "include"
        })
            .then(function (_a) {
                var json = _a.json;
                return ({ data: json });
            })
            .catch(err => console.error(err));
    },
    getOne: (resource, params) => {
        const url = `${urlPrefix}/user/edit?sn=${params.id}`;

        const request = new Request(url, {
            method: 'get',
        });
        return fetch(request, {
            credentials: "include"
        })
            .then(function (_a) {
                return Promise.all([_a, _a.json()]);
            })
            .then(results => {
                return {headers: results[0].headers, json: results[1]};
            })
            .then(function (o) {
                return {
                    data: o.json.result,
                };
            });
    },
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };

        const url = urlPrefix + GetListResourceMap.get(resource) + `?${stringify(query)}`;
        const request = new Request(url, {
            method: 'get',
        });
        return fetch(request, {
            credentials: "include"
        }).then(function (_a) {
            return Promise.all([_a, _a.json()]);
        })
            .then(results => {
                return {headers: results[0].headers, json: results[1]};
            })
            .then(function (o) {
                return {
                    data: o.json.result || [],
                    total: parseInt(o.headers.get('X-Total-Count'), 10),
                };
            });
    },
};

export default godmod;
