import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Toolbar, SaveButton } from 'react-admin';


const toolbarStyles = (theme) => ({
    toolbar: {
        display: 'block',
        marginTop: '7px',
        backgroundColor: 'white',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px'
        }
    }
});

const toolbarButtonStyles = {
    root: {
        width: '100%',
        textTransform: 'none',
        display: 'block',
        boxShadow: 'none',
        borderRadius: '9px',
        marginTop: '12px',
        '&:hover, &:active': {
            boxShadow: 'none'
        }
    }
};

const toolbarButtonSaveStyles = {
    button: Object.assign({}, toolbarButtonStyles.root, {marginTop: 0}),
    icon: {
        display: 'none'
    }
};

const BackButton = withStyles(toolbarButtonStyles)(({
    handleSubmit,
    handleSubmitWithRedirect,
    onSave,
    invalid,
    pristine,
    saving,
    submitOnEnter,
    basePath,
    classes,
    ...rest
}) => <Button classes={classes} color="primary" {...rest} />);

const StyledSaveButton = withStyles(toolbarButtonSaveStyles)(({classes, ...props}) => (
    <SaveButton classes={classes} {...props}/>
));

const LoginToolbar = withStyles(toolbarStyles)(({classes, showBackButton, backHandler, ...props}) => {
    const backButton = (<BackButton onClick={backHandler}>
        Вернуться к вводу Email
    </BackButton>);
    const style = showBackButton ? {marginTop: '36px'} : null;
    return (<Toolbar style={style} classes={classes} {...props} >
        <StyledSaveButton
            label="Далее"
            submitOnEnter={true}
        />
        {showBackButton ? backButton : null}
    </Toolbar>);

});


export default LoginToolbar;