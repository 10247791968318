import * as React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';
import { useTranslate } from 'react-admin';
import { TextInput, Filter, Title, ImageField } from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

import {
    Box,
    Avatar,
} from '@material-ui/core';


const GroupFilter = (props: any) => {
    const translate = useTranslate();
    return (<Filter {...props}>
        <TextInput label="Search" source="q" fullWidth={true} alwaysOn resettable />
        <QuickFilter source="type_channel" label={translate("management.searchResult.Channels.name")} defaultValue={true} />
        <QuickFilter source="type_group" label={translate("management.searchResult.Groups.name")} defaultValue={true} />
    </Filter>);
};

const QuickFilter = (props: any) => {
    const translate = useTranslate();
    return <Chip label={props.label} />;
};

const AvatarImage = (props: any) => {
    return <Avatar src={props.record.avatar} />;
}

const GroupList = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
        <h1>Управление</h1>
        <List  title={<span></span>} {...props} 
        filters={<GroupFilter />} 
        filterDefaultValues={{ q: "group" }} 
        exporter={false} 
        bulkActionButtons={false}>
            <Datagrid>
                <AvatarImage label="" source="avatar" {...props} />
                <TextField label={translate("management.searchResult.Name.name")} source="name" />
                <TextField label={translate("management.searchResult.Stamp.name")} source="stamp" />
                <TextField label={translate("management.searchResult.Type.name")} source="type" />
                <TextField label={translate("management.searchResult.Members.name")} source="members_count" />
            </Datagrid>
        </List>
        </div>
    );
}

export default GroupList;
