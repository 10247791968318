import React from 'react';
import { useTranslate } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';

function getUploadedDocuments() {
    const request = new Request(process.env.REACT_APP_API_HOST + '/api/dashboard', {
        method: 'POST',
        body: JSON.stringify(),
        credentials: 'include',
        headers: new Headers({'Content-Type': 'application/json'}),
    });

    return fetch(request)
        .then(response => {
            if (response.status !== 200) {
                throw new Error("Internal server error");
            }

            return response.json();
        })
}

function getVersions() {
    const request = new Request(process.env.REACT_APP_API_HOST + '/api/misc/client_versions', {
        method: 'GET',
        headers: new Headers({'Content-Type': 'application/json'}),
    });

    return fetch(request)
        .then(response => {
            if (response.status !== 200) {
                throw new Error("Internal server error");
            }

            return response.json();
        })
}

const useStyles = makeStyles({
    link: {
        color: '#005FF9',
        fontSize: '15px',
        lineHeight: '20px'
    }
});

const UploadedDocumentsComponent = () => {
    const [list, setList] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        getUploadedDocuments()
            .then(data => {
                if (data.status && data.status.code === 200) {
                    if (data.result.documents) {
                        setList(data.result.documents);
                    }
                }
            });
    }, []);

    if (list.length) {
        return (<div>
            {<ul>{list.map((item, i) => {
                const name = item.split('.').slice(0, -1).join('.');
                return <li key={"doc-" + i}>
                    <a className={classes.link} target="_blank" rel="noopener noreferrer" href={"/upload/documents/" + item}>{name}</a>
                </li>;
            })}</ul>}
        </div>);
    } else {
        return "Список документов пуст";
    }
};

const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) => {
    return first.toLocaleUpperCase(locale) + rest.join('')
}

const formatOSName = (osName) => {
    if (osName === "macos") {
        return "macOS"
    }

    if (osName === "ios") {
        return "iOS"
    }

    return capitalizeFirstLetter(osName);
}


const ClientVersionsComponent = () => {
    const [versions, setVersions] = useState(new Map());

    useEffect(() => {
        getVersions()
            .then(data => {
                if (data.status && data.status.code === 200 && data.result) {

                    let versionsArray = [
                        ["myteam_release", data.result['myteam_release']]
                    ];

                    for (let key in data.result['myteam_clients']) {
                        versionsArray.push([key, data.result['myteam_clients'][key]])
                    }

                    setVersions(new Map(versionsArray));
                }
            });
    }, []);

    if (versions.size) {
        return (<div>
            <div>Текущая версия системы: {versions.get('myteam_release')}</div>
            <div>Версии клиентских приложений: </div>
            <ul>
            {[...versions.keys()].map(k => {
                if (k !== "myteam_release") {
                    return (
                        <li key={k}>{formatOSName(k)}: {versions.get(k)}</li>
                    );
                }
            })}
            </ul>
        </div>);
    } else {
        return "Информация отсутствует";
    }
};

const Dashboard = (props) => {
    const translate = useTranslate();

    return (
        <Box {...props} width="auto">
            <h1>{translate("resources.Information.name")}</h1>
            <Card>
                <CardHeader title="Документы описания системы"/>
                <CardContent>
                    <UploadedDocumentsComponent />
                </CardContent>
            </Card>

            <Card>
                <CardHeader title="Версии системы и клиентских приложений"/>
                <CardContent>
                    <ClientVersionsComponent />
                </CardContent>
            </Card>
        </Box>
    );
};

export default Dashboard;
