import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { userLogin as userLoginAction } from 'react-admin';
import { useLogin, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';

import { withStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { TextInput, SimpleForm, email } from 'react-admin';
import LoginToolbar from './LoginToolbar';

import authProvider from "../../DataProvider/authProvider";
import {CheckBoxIcon, CheckBoxIconUnchecked} from "../MenuIcons/Icons";


const theme = createMuiTheme({
    typography: {
        fontSize: 13
    },
    palette: {
        primary: {
            main: '#005ff9'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: '15px',
            },
        },
        RaCardContentInner: {
            root: {
                padding: 0
            }
        },
        RaFormInput: {
            input: {
                width: '300px'
            }
        },
        MuiFilledInput: {
            root: {
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px'
            },
            inputMarginDense: {
                paddingTop: '14px',
                paddingBottom: '13px'
            }
        },
        MuiInputLabel: {
            shrink: {
                display: 'none'
            }
        }
    },
});


const styles = {
    root: {
        textAlign: 'center'
    },
    form: {
        '& .ra-input-password input': {
            fontSize: '18px',
            paddingTop: '12px',
            paddingBottom: '11px'
        }
    },
    title: {
        width: '300px',
        margin: '16px',
        fontSize: '22px'
    },
    description: {
        width: '300px',
        margin: '16px',
        fontSize: '15px',
        '& a': {
            whiteSpace: 'nowrap',
            color: theme.palette.primary.main
        }
    }
};

const rememberMeStyles = {
    root: {
        justifyContent: 'center',
        marginTop: '22px'
    }
};


const RememberMeLabel = withStyles(rememberMeStyles)(({basePath, ...props}) => (
    <FormControlLabel {...props}/>
));

const validateEmail = email();

const EmailInput = ({value, ...props}) => {
    const form = useForm();
    const initial = useRef();
    useEffect(() => {
        if (!initial.current && value) {
            initial.current = true;
            form.change('email', value);
        }
    });
    return <TextInput {...props}/>
};

const EmailForm = ({email, rememberMe, onRememberMeChange, ...props}) => {
    return <SimpleForm {...props}>
        <EmailInput value={email} label="Логин" source="email" validate={validateEmail} />
        <RememberMeLabel onChange={onRememberMeChange} checked={rememberMe} label="Запомнить мои данные" control={
            <Checkbox
                color="primary"
                name="rememberMe"
                checkedIcon={CheckBoxIcon()}
                icon={CheckBoxIconUnchecked()}
            />}
        />
    </SimpleForm>;
};

const PassForm = ({email, rememberMe, onRememberMeChange, ...props}) => {
    return <SimpleForm {...props}>
        <TextInput label="Одноразовый пароль" type="password" source="password" required={false} />
    </SimpleForm>;
};

const LoginForm = ({ classes, userLogin }) => {
    let [email, setEmail] = useState('');
    const [,setLoading] = useState(false);
    const [codeRequested, setCodeRequested] = useState(false);
    const [rememberMe, setRememberMe] = useState(() => {
        if (!email) {
            email = localStorage.getItem('remembered') || '';
            return !!email;
        }
        return false;
    });

    const emailLink = `<a>${email}</a>`;
    const title = codeRequested ? 'Введите пароль' : 'Введите email';
    const description = codeRequested ? `Ведите одноразовый пароль, который был отправлен на ${emailLink}` : 'Введите ваш корпоративный email, на него будет отправлен пароль для входа';

    const login = useLogin();

    const notify = useNotify();
    const submit = (e) => {
        if (e.email) {
            if (rememberMe) {
                localStorage.setItem('remembered', e.email);
            } else {
                localStorage.removeItem('remembered');
            }
            setEmail(e.email);
        } else {
            e.email = email;
        }
        authProvider.OTP(e)
            .catch((err) => {
                notify(err.message);
            })
            .then((r) => {
                if (r === undefined) {
                    return
                }

                if (r.status.code === 202) {
                    login({email});
                    return;
                }

                if (r.status.code !== 200) {
                    return notify(r.status.reason)
                }

                setCodeRequested(true);
                return notify("One-time password sent to your email");
            })
    };

    const backHandler = () => {
        setCodeRequested(false);
    };

    const onRememberMeChange = (e) => {
        setRememberMe(e.target.checked);
    };

    useEffect(() => {
        const { searchParams } = new URL(window.location.href);
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (code && state) {
            setLoading(true);
            userLogin({ code, state });
        }
    }, [userLogin]);

    const CurrentForm = codeRequested ? PassForm : EmailForm;
    const formProps = {email, onRememberMeChange, rememberMe};

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <h1 className={classes.title}>{title}</h1>
                <div className={classes.description} dangerouslySetInnerHTML={{__html: description}}></div>
                <CurrentForm {...formProps} className={classes.form} toolbar={<LoginToolbar showBackButton={codeRequested} backHandler={backHandler}/>} save={submit}/>
            </div>
        </ThemeProvider>
    );
};

const mapDispatchToProps = {
    userLogin: userLoginAction,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(LoginForm));