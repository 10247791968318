import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {useState, useEffect, useCallback, useRef} from 'react';
import moment from "moment";
import {CheckBoxIcon, CheckBoxIconUnchecked} from "../MenuIcons/Icons";
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles';

interface Report {
    UUID: string,
    Timestamp: number,
    User: string,
    Status: string,
}

function getLatestReport() {
    const request = new Request(process.env.REACT_APP_API_HOST + '/api/mchat/report', {
        method: 'GET',
        credentials: 'include',
        headers: new Headers({'Content-Type': 'application/json'}),
    });

    return fetch(request)
        .then(response => {
            if (response.status !== 200) {
                throw new Error("Internal server error");
            }

            return response.json();
        })
}

function newMChatReport(checkboxState: any) {
    let formData = new FormData();
    formData.append("with_groups", checkboxState.withGroups ? "1" : "0")
    formData.append("with_channels", checkboxState.withChannels ? "1" : "0")

    const request = new Request(process.env.REACT_APP_API_HOST + '/api/mchat/report', {
        method: 'POST',
        body: formData,
        credentials: 'include',
    });

    return fetch(request)
        .then(response => {
            if (response.status !== 200) {
                throw new Error("Internal server error");
            }

            return response.json();
        })
        .then<Report>(data => {
            if (data.status && data.status.code === 200) {
                return data.result.last_report;
            }
        })
}

const useStyles = makeStyles({
    link: {
        color: '#005FF9',
        fontSize: '15px',
        lineHeight: '20px'
    },
    reportDate: {
        color: 'grey',
        fontSize: '15px',
        lineHeight: '20px',
        marginLeft: '10px',
    },
    generateButton: {
        textTransform: 'none',
        color: '#FFFFFF',
        backgroundColor: '#005FF9',
        height: '40px',
        width: '145px',
        boxShadow: 'none',
    },
    generateButtonDisabled: {
        opacity: '0.48',
        textTransform: 'none',
        color: '#FFFFFF',
        backgroundColor: '#005FF9',
        height: '40px',
        width: '145px',
        boxShadow: 'none',
    },
    header: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '40px',
    },
    headerRoot: {
        paddingLeft: '0',
    },
    cardRoot: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px',
        height: '228px',
        padding: '32px',
        boxSizing: 'border-box'
    },
    cardContent: {
        padding: '0',
        color: '#2C2D2E',
    },
    font: {
        fontSize: '15px',
        fontFamily: 'Arial',
        lineHeight: '20px',
    },
    checkBox: {

    }
});

const theme = createMuiTheme({
    palette: {
        action: {
            disabledBackground: '#005FF9',
            disabled: '#FFFFFF'
        }
    }
});

const MChatDumpForm = (props: any) => {
    const classes = useStyles();

    const [isSending, setIsSending] = useState(false)
    const [canCreateNewReport, setCanCreateNewReport] = useState(false)
    const [report, setReport] = useState<null | Report>(null);

    const [checkboxState, setCheckboxState] = useState({
        withGroups: true,
        withChannels: true,
    });

    const isMounted = useRef(true);
    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;

        const check = () => {
            if (!canCreateNewReport) {
                getLatestReport().then(data => {
                    if (data.status && data.status.code === 200) {
                        if (data.result.last_report) {
                            setReport(data.result.last_report);
                            if (data.result.can_create) {
                                setCanCreateNewReport(true);
                            } else {
                                checkAgain();
                            }
                        }

                        if (data.result.can_create) {
                            setCanCreateNewReport(true);
                        }
                    }
                });
            }
        };
        const checkAgain = () => (timeoutId = setTimeout(check, 2000));
        check();

        return () => {
            isMounted.current = false;
            clearTimeout(timeoutId);
        };
    }, [canCreateNewReport]);

    const reportStatusString = () => {
        if (report && report!.Status === "ready") {
            const reportDateStr = moment(report!.Timestamp*1000).format('YYYY-MM-DD в HH:mm');

            return (<div>
                <a className={classes.link} target="_blank" rel="noopener noreferrer"
                   href={"/api/mchat/report/download/?uuid=" + report!.UUID}>
                    Скачать список участников
                </a>
                <span className={classes.reportDate}>{reportDateStr}</span>
            </div>);
        } else if (report && (report!.Status === "new" || report!.Status === "converting")) {
            return (<div>
                Идет генерация списка...
            </div>);
        } else {
            return (<div>
                Список участников еще не генерировался
            </div>);
        }
    };

    const sendRequest = useCallback(async () => {
        if (isSending) {
            return
        }

        setIsSending(true);

        let response = await newMChatReport(checkboxState)
        if (!response || response.Status === "ready" || response.Status === "fail") {
            setCanCreateNewReport(true);
        } else {
            setCanCreateNewReport(false);
        }

        setIsSending(false);
    }, [isSending, checkboxState])

    const handleChange = (event: any) => {
        setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <CardHeader classes={{ title: classes.header, root: classes.headerRoot }} title="Выгрузка участников">
                </CardHeader>
                <Card className="card"  classes={{ root: classes.cardRoot }}>
                    <form>
                    <CardContent classes={{ root: classes.cardContent }}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    checkedIcon={CheckBoxIcon()}
                                    icon={CheckBoxIconUnchecked()}
                                    name="withGroups"
                                    color="primary" />
                                }
                                label="Участники всех групп"
                                classes={{ label: classes.font }}
                                checked={checkboxState.withGroups}
                                onChange={handleChange}
                                value={"with_groups"}
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checkedIcon={CheckBoxIcon()}
                                    icon={CheckBoxIconUnchecked()}
                                    name="withChannels"
                                    color="primary" />
                                }
                                label="Участники всех каналов"
                                classes={{ label: classes.font }}
                                checked={checkboxState.withChannels}
                                onChange={handleChange}
                                value={"with_channels"}
                            />
                        </FormGroup>
                        <div style={{ paddingTop: '10px', paddingBottom: '20px', color: '#919399' }} className={classes.font}>
                            {reportStatusString()}
                        </div>
                        <ThemeProvider theme={theme}>
                            <Button
                                name="submit"
                                className={classes.font}
                                variant="contained"
                                color="primary"
                                onClick={sendRequest}
                                disabled={!canCreateNewReport}
                                classes={{ root: classes.generateButton, disabled: classes.generateButtonDisabled }}
                            >
                                Сгенерировать
                            </Button>
                        </ThemeProvider>
                    </CardContent>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
}

export default MChatDumpForm;
