import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 32,
        height: 20,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 2,
        '&$disabled': {
            '& $thumb': {
                color: '#ffffff',
            },
            '& + $track': {
                borderColor: '#BFC1C7',
                backgroundColor: '#BFC1C7',
                opacity: 0.5,
            }
        },
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#005FF9',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: 'none',
    },
    track: {
        borderRadius: 26 / 2,
        border: '1px solid #BFC1C7',
        backgroundColor: '#BFC1C7',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
        boxSizing: 'border-box',
    },
    checked: {},
    focusVisible: {},
    disabled: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
                disabled: classes.disabled,
            }}
            {...props}
        />
    );
});
