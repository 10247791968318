import React from 'react';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import LoginForm from './LoginForm';

const styles = {
    avatar: {
        display: 'none'
    },
    main: {
        backgroundImage: 'radial-gradient(circle at 50% 14em, #f0f1f4 0%, #bfc1cb 60%, #bfc1cb 100%)'
    }
};
const LoginPage = withStyles(styles)(({classes, ...props}) => (
    <Login classes={classes} {...props}>
        <LoginForm />
    </Login>
));

export default LoginPage;